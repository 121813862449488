
/*
 * This is used across the components
 * It pulls in the associated brand data
 * The brand is an image as the > and < doesn't look great
 */

// Icon mapping
interface IMapping {
  [key: string]: any;
}

export const BrandAttributes: IMapping = {
  bpress: {
    icon: "/logos/logo-bpress.svg",
    width: "116px",
    title: ">B< Press",
    src: "/products/feature/product-b-press.jpg",
    srcGas: "/products/feature/product-b-press-gas.jpg",
    srcHighTemp: "/products/feature/product-b-press-high-temp.jpg",
    url: "/products/b-press",
  },
  "b-press": {
    icon: "/logos/logo-bpress.svg",
    width: "116px",
    title: ">B< Press",
    src: "/products/feature/product-b-press.jpg",
    url: "/products/b-press",
  },
  bpressxl: {
    icon: "/logos/logo-bpress-xl.svg",
    width: "155px",
    title: ">B< Press XL",
    src: "/products/feature/product-b-pressxl.jpg",
    srcGas: "/products/feature/product-b-pressxl-gas.jpg",
    url: "/products/b-press-xl",
  },
  "b-press-xl": {
    icon: "/logos/logo-bpress-xl.svg",
    width: "155px",
    title: ">B< Press XL",
    src: "/products/feature/product-b-pressxl.jpg",
    url: "/products/b-press-xl",
  },
  bmaxipro: {
    icon: "/logos/logo-maxipro.svg",
    width: "143px",
    title: ">B< MaxiPro",
    src: "/products/feature/product-b-maxipro.jpg",
    url: "/products/b-maxipro",
  },
  "b-maxi-pro": {
    icon: "/logos/logo-maxipro.svg",
    width: "143px",
    title: ">B< MaxiPro",
    src: "/products/feature/product-b-maxipro.jpg",
    url: "/products/b-maxipro",
  },
  bpressstainlesssteel: {
    icon: "/logos/logo-bpress-stainless-steel.svg",
    width: "310px",
    title: ">B< Press Stainess Steel",
    src: "/products/feature/product-b-pressstainlesssteel.jpg",
    url: "/products/b-press-stainless-steel",
  },
  "b-press-stainless-steel": {
    icon: "/logos/logo-bpress-stainless-steel.svg",
    width: "310px",
    title: ">B< Press Stainess Steel",
    src: "/products/feature/product-b-pressstainlesssteel.jpg",
    url: "/products/b-press-stainless-steel",
  },
  bpressstainlesssteelv2: {
    icon: "/logos/logo-bpress-stainless-steel-v2.svg",
    width: "169px",
    title: "Bpress Stainess Steel",
    src: "/products/feature/product-b-pressstainlesssteel.jpg",
    url: "/products/b-press-stainless-steel",
  },
  romaxcompacttt: {
    icon: "/logos/logo-romax-compact-tt.svg",
    width: "222px",
    title: "Romax Compact TT",
    src: "/products/feature/tools-romax-compact-tt.jpg",
    url: "/tools-and-accessories#romax-compact-tt",
  },
  romax400: {
    icon: "/logos/logo-romax-4000.svg",
    width: "143px",
    title: "Romax 4000",
    src: "/products/feature/tools-romax-4000.jpg",
    url: "/tools-and-accessories#romax-4000",
  },
  accessories: {
    icon: "/logos/logo-accessories.svg",
    width: "137px",
    title: "Accessories",
    src: "/products/feature/tools-accessories.jpg",
    url: "/tools-and-accessories#accessories",
  },
  approved: {
    icon: "/logos/logo-approved-tools.svg",
    width: "181px",
    title: "Approved Tools",
    src: "/products/feature/tools-approved.jpg",
    url: "/tools-and-accessories#approved-tools",
  },
};
