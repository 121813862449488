import React from "react";
import { Link, graphql, withPrefix } from "gatsby";

import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import Banner from "../components/Content/Banner/Index";
import LeadIn from "../components/Content/LeadIn/Index";

import { BrandAttributes } from "../helpers/hooks/BrandQuery";

interface Props {
  readonly data: PageQueryData;
  readonly pageContext: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    previous?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next?: any;
  };
}

const CaseStudyTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const caseStudy = data.markdownRemark;
  const { previous, next } = pageContext;

  const {
    blockquoteBody,
    blockquoteFooter,
    category,
    metaDescription,
    metaTitle,
    images,
    summary,
    taxonomy,
  } = caseStudy.frontmatter;
  const { html } = caseStudy;

  const brandType = category ? category.replace(/\W+/g, "").toLowerCase() : "";
  const featuredImage: ImageData | undefined = images?.length
    ? images[0]
    : undefined;

  return (
    <Layout>
      <Seo title={metaTitle} description={metaDescription} />

      <article>
        <header>
          <Banner
            type="blog"
            summary={summary}
            brandMediaTitle={BrandAttributes[brandType]?.title}
            brandMediaUrl={BrandAttributes[brandType]?.icon}
            brandMediaWidth={BrandAttributes[brandType]?.width}
            featureMediaUrl={featuredImage?.path}
            featureMediaTitle={featuredImage?.altText}
            removePadding={true}
          />
          {taxonomy.length && (
            <div className="uk-section uk-section-small">
              <div className="uk-container">
                <div className="uk-margin-medium">
                  <dl
                    className="uk-description-list uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@l uk-grid-small"
                    uk-grid=""
                  >
                    {taxonomy.map((term: TaxonomyData, index: number) => (
                      <div key={`taxonomyTerm${index}`}>
                        <dt>{term.key}</dt>
                        <dd>{term.value}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          )}
        </header>

        <div className="uk-section c-case-study">
          <div
            className="uk-container"
            uk-margin="margin: uk-margin-xlarge-top;"
          >
            <div
              className={`${
                images.length > 1 ? "uk-child-width-expand@m" : ""
              } uk-grid-large c-case-study__columns`}
              uk-grid=""
            >
              {/* body */}
              <div
                className="uk-child-width-auto@m c-viewport__green-headers"
                id="html"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: html }}
                  className="uk-margin-auto"
                  style={{ maxWidth: `${images.length > 1 ? "554px" : ""}` }}
                />
              </div>

              <div className="uk-flex-first uk-flex-last@m">
                {/* slider */}
                {images.length >= 1 && (
                  <div
                    uk-slider={`center: ${images.length > 2}; autoplay: true`}
                    uk-sticky="offset: 40; bottom: #html; media: 768"
                    className={images.length == 1 ? "c-printonly" : ""}
                  >
                    <div
                      className="uk-position-relative uk-visible-toggle uk-light"
                      tabIndex={-1}
                    >
                      <ul
                        className="uk-slider-items uk-grid uk-grid-small"
                        uk-lightbox=""
                      >
                        {images.map((image: ImageData, index: number) => (
                          <li
                            className="uk-width-5-6"
                            key={`sliderItem${index}`}
                          >
                            <a
                              href={withPrefix(image.path)}
                              data-alt={image.altText}
                              data-caption={image.caption}
                              data-type="image"
                            >
                              <div className="uk-cover-container">
                                <canvas
                                  width="900"
                                  height="670"
                                  className="c-noprint"
                                ></canvas>
                                <img
                                  src={withPrefix(image.path)}
                                  alt={image.altText}
                                  className="uk-width-full"
                                  uk-cover=""
                                />
                              </div>
                            </a>
                          </li>
                        ))}
                      </ul>

                      <a
                        className="uk-position-center-left uk-position-small uk-hidden-hover c-noprint"
                        href="#"
                        uk-slidenav-previous=""
                        uk-slider-item="previous"
                      ></a>
                      <a
                        className="uk-position-center-right uk-position-small uk-hidden-hover c-noprint"
                        href="#"
                        uk-slidenav-next=""
                        uk-slider-item="next"
                      ></a>
                    </div>

                    <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin c-noprint"></ul>
                  </div>
                )}
              </div>
            </div>

            {/* blockquote */}
            {blockquoteBody && (
              <div className="c-case-study__blockquote uk-background-muted uk-text-emphasis uk-padding-large uk-margin-remove-adjacent uk-margin-large-bottom">
                <blockquote
                  className="uk-margin-auto uk-margin-remove-bottom"
                  style={{ maxWidth: "790px" }}
                >
                  <div
                    className="c-talking-points"
                    dangerouslySetInnerHTML={{ __html: blockquoteBody }}
                  />
                  <footer
                    dangerouslySetInnerHTML={{ __html: blockquoteFooter }}
                  />
                </blockquote>
              </div>
            )}

            {/* print button */}
            <div className="uk-text-center c-noprint">
              <button
                onClick={() => window.print()}
                className="uk-button uk-button-primary"
              >
                Download Case Study
              </button>
            </div>

            <div className="c-noprint">
              {(previous || next) && (
                <ul className="uk-pagination">
                  {previous && (
                    <li>
                      <Link to={previous.frontmatter.slug} rel="prev">
                        <span
                          className="uk-margin-small-right"
                          uk-pagination-previous="ratio: 2"
                        ></span>{" "}
                        Prev Case
                        <span className="uk-visible@s"> Study</span>
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li className="uk-margin-auto-left">
                      <Link to={next.frontmatter.slug} rel="next">
                        Next Case
                        <span className="uk-visible@s"> Study </span>{" "}
                        <span
                          className="uk-margin-small-left"
                          uk-pagination-next="ratio: 2"
                        ></span>
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </article>
      <LeadIn
        section="primary"
        brandMediaUrl="/icons/icon-search-white.svg"
        brandMediaTitle="Download"
        brandMediaWidth="69px"
        summary="Find out more about the labor saving >B< Press products."
        paddingSize="small"
        buttonValue="View Products"
        buttonUrl="/products/b-press"
      />
    </Layout>
  );
};

interface ImageData {
  altText: string;
  caption?: string;
  path: string;
}

interface TaxonomyData {
  key: string;
  value: string;
}

interface PageQueryData {
  markdownRemark: {
    id?: string;
    excerpt?: string;
    html: string;
    frontmatter: {
      blockquoteBody: string;
      blockquoteFooter: string;
      category: string;
      date: string;
      images: ImageData[] | [];
      metaTitle: string;
      metaDescription: string;
      summary: string;
      taxonomy: TaxonomyData[] | [];
      title: string;
    };
  };
}

export const pageQuery = graphql`
  query CaseStudyBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 2500)
      html
      frontmatter {
        blockquoteBody
        blockquoteFooter
        category
        date(formatString: "MMMM DD, YYYY")
        images {
          path
          altText
          caption
        }
        metaTitle
        metaDescription
        summary
        taxonomy {
          key
          value
        }
        title
      }
    }
  }
`;

export default CaseStudyTemplate;
